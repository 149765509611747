import React from 'react';

const EndotelioContent = <div className="study-content">
<h1>Recuento endotelial</h1>
<div className="sep"></div>

<h4>¿Qué es el endotelio?</h4>

Es la capa más interna de la córnea, formada por unas células planas muy fina, dispuestas en forma de mosaico. Su buen funcionamiento es fundamental para mantener la córnea completamente seca, lo que hace que sea trasparente. 

<h4>¿En qué consiste el Recuento Endotelial?</h4>

El recuento de células endoteliales es una prueba que permite la valoración de la densidad, el tamaño y la forma de las células endoteliales. 

<h4>¿Cómo es un endotelio normal?</h4>

5.000 células por mm2 tenemos al nacer., Cantidad que va disminuyendo durante la vida, de modo progresivo. En un adulto normal existen entre 1.500 y 2.500 células por mm2. El nivel mínimo necesario para una función normal oscila entre 600 y 900 células por mm2. Por debajo de 500 células por mm2, la córnea acumula agua en su interior y pierde transparencia. Es el llamado edema corneal-

<h4>¿Para qué situaciones es útil conocer el Recuento Endotelial?</h4>

Se utiliza sobre todo en distrofias corneales endoteliales como la distrofia de Fuchs. También en el seguimiento postoperatorio de trasplantes de córnea. Y en todas las en que puede haber lesión o pérdida de las células endoteliales. Es importante también para conocer el estado del endotelio previo a una intervención quirúrgica como puede ser la cirugía de cataratas.

<div className="study-img-cont">
<img src={require("../../assets/images/endotelio.jpg")}></img>
</div>

 
 </div>   

export { EndotelioContent };