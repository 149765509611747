import React from 'react';
import './Header.scss';
import { NavLink } from "react-router-dom";
import Dropdown from "../dropdown/dropdown";
import { slide as Menu } from 'react-burger-menu';
import LazyLoad from 'react-lazyload';

class HeaderComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            hideNav: false,
            menuOpen: false
         };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        let currentHideNav = (window.innerWidth <= 1100);
        if (currentHideNav !== this.state.hideNav) {
            this.setState({ hideNav: currentHideNav });
        }
    }

    closeMenu () {
        this.setState({menuOpen: false})
    }

    redirectToAppointmentPage = () => {
        window.location.href = "http://comorbelli.dyndns.org/treelan/turnos_online/logme.php";
    }

    render() {
        const especialidadOptions = [{ text: "Cirugia Refractiva", path: "cirugiaRefractiva" }, { text: "Degeneracion Macular", path: "degeneracionMaculura" },
        { text: "Cornea y superficie Ocular", path: "cornea" }, { text: "Presbicia", path: "presbicia" }, { text: "Cataratas", path: "cataratas" }
            , { text: "Glaucoma", path: "glaucoma" }, { text: "Retina Y Vitreo", path: "retina" }];

        const estudiosOptions = [{ text: "OCT (tomografia de coherencia Optica)", path: "OCT" }, { text: "HRT (Laser confocal de barrido)", path: "HRT" }
            , { text: "Biometria óptica y topografía", path: "biometria" }, { text: "CVC (campo visual Computarizado)", path: "CVC" }, 
            { text: "Ecografía", path: "ecografia" },{ text: "Recuento endotelial", path: "Endotelio" }];

        const HeaderMenu = () => {
            if (this.state.hideNav) {
                return null;
            }
            else {
                return (<ul>
                    <li>
                        <NavLink exact
                            activeClassName='active' to="/">Inicio</NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName='active' to="/medicos">Nuestros Médicos</NavLink>
                    </li>
                    <Dropdown
                        value={"Especialidades"}
                        options={especialidadOptions}
                        id={"dropdown-especialidades"}
                    />
                    <Dropdown
                        value={"Estudios"}
                        options={estudiosOptions}
                        id={"dropdown-estudios"}
                    />
                    <li>
                        <NavLink activeClassName='active' to="/contacto">Contacto</NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName='active' to="/turnos">Turnos</NavLink>
                    </li>
                </ul>)
            }
        }

        const BurguerMenu = () => {
            if(this.state.hideNav){
                return (<Menu isOpen={this.state.menuOpen}>                   
                      <NavLink exact activeClassName='active' to="/" onClick={() => this.closeMenu()}>Inicio</NavLink>
                      <NavLink activeClassName='active' to="/medicos" onClick={() => this.closeMenu()}>Nuestros Médicos</NavLink>
                      <NavLink activeClassName='active' to="/cirugiaRefractiva" onClick={() => this.closeMenu()}>Especialidades</NavLink>
                      <NavLink activeClassName='active' to="/OCT" onClick={() => this.closeMenu()}>Estudios</NavLink>
                    <NavLink activeClassName='active' to="/contacto" onClick={() => this.closeMenu()}>Contacto</NavLink>
                    <NavLink activeClassName='active' to="/turnos" onClick={() => this.closeMenu()}>Turnos</NavLink>
                  </Menu>)
            }
            else{
                return null;
            }
        }

        return (
            <React.Fragment>
            <div className="header-container">
                <div className="container">
                    <LazyLoad height={200} offset={100} once>
                        <NavLink to="/"><img src={require("../../assets/images/LogoMorbelliHori.png")} alt="logoMorbelli"></img></NavLink>
                    </LazyLoad>
                    <div>
                        <HeaderMenu></HeaderMenu>                       
                    </div>
                </div>
           
            </div>
             <BurguerMenu></BurguerMenu>
             </React.Fragment>)
    }


}

export default HeaderComponent;