import React from 'react';
import { useState } from 'react';
import './Appointment.scss';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { Link } from 'react-router-dom'

const Appointment = (props) => {

    const [open,setOpen] = useState(true);    

    const closeCard = () => {
        setOpen(false);
    }

    const redirectToAppointmentPage = () => {
        window.location.href = "http://comorbelli.dyndns.org/treelan/turnos_online/logme.php";
    }

    const RenderComponent = () => {
        
        if(open){
             return (        
                <div className="appointment-container">
                    <Card style={{ 'background': '#4c628e', 'border' : 'white 1px solid' }}>
                        <CardContent> 
                        <div className="close-cont">
                        <IconButton onClick={closeCard} style={{ padding: 0, float: 'right', color : 'white'}} aria-label="delete">
                        <ClearIcon fontSize='small'/>
                        </IconButton>   
                        </div>
                        <div className="content-cont">                
                            <h2>Solicitá tu turno</h2>  
                            <div className="sep"></div>                  
                            <p>Reserva tu turno en tres simples pasos.</p> 
                        </div>
                        </CardContent>
                        <CardActions style={{ justifyContent: 'center' }}>
                            <Button size="small" variant="contained" style={{ color : '#4c628e', fontWeight: 'bold', 
                            padding: '14px', marginBottom: '15px', border: 'white 1px solid',
                            borderRadius: 0}} component={Link} to="turnos">Solicitar Turno</Button>
                        </CardActions>               
                    </Card>
                </div>
            )
        }
        else{
            return null
        }
    }

    return <RenderComponent></RenderComponent>
}

export default Appointment;