import Accordion from '../../components/accordion/Accordion';
import React from 'react';

const degeneracionAccordion = <div className="accordion_cont"><h1>Degeneracion Macular</h1>
    <Accordion
        active={true}
        title="¿QUE ES LA DEGENERACION MACULAR?"
        content='La DMAE (degeneración macular asociada a la edad) es una enfermedad ocular de tipo degenerativo que afecta a una zona de su retina, llamada MÁCULA, que es la responsable de su visión central.
        <br><br>
        Es la causa más frecuente de ceguera legal por encima de los 50 años en nuestro medio; con una tasa de prevalencia 2-10%, aumentando claramente después de los 70 años.
        <br><br>
        Existen diferentes tipos de DMAE:
        <br><br>
        <ul><li>DMAE seca o atrófica: Es la más frecuente y benigna, con una progresión lenta a lo largo de los años y pérdida de visión total, poco frecuente, que puede llevar décadas.
        </li>
        <li>DMAE húmeda o exudativa: Es mucho menos frecuente pero es la forma más agresiva con una progresión en meses y pudiendo llegar a una pérdida total de su visión central e impedir realización de sus actividades diarias, como lectura, conducción.
        </li></ul>
        En pacientes con un ojo afectado tienen un 50% de posibilidades de bilateralidad a los 5 años.'
    />
    <Accordion
        active={false}
        title="SINTOMAS"
        content='Como se ha dicho, es una enfermedad que suele afectar a los dos ojos, aunque puede no ser al mismo tiempo, y hay gente que sólo nota síntomas cuando se ven afectados ambos ojos.
        <br><br>
        Inicialmente se puede notar alguno de los siguientes síntomas:
        <br><br>
        <ul><li>Visión borrosa central, tendrá dificultades para reconocer a la gente por la calle. Leer, ver la televisión, pagar con monedas, actividades que necesitan una buena visión central se verán comprometidas. Además:
        </li>
        <li>Tendrá pocos problemas para manejarse en su casa o para caminar de un sitio a otro.
        </li>
        <li>Molestias con luces intensas o necesidad de más luz para ciertas actividades como la lectura.
        </li>
        <li>La percepción de líneas rectas aparece distorsionada, torcidas, inclinadas o cortadas. Puede observarlo al mirar los marcos de las puertas o de las ventanas.
        </li>
        <li>Dificultad para apreciar distancias o alturas, adquiriendo los objetos formas y tamaños anormales, por lo que tendrá dificultad para bajar escaleras, colocar objetos.
        </li></ul>
        Si el proceso está muy avanzado se verá un punto o mancha negra central.'
    />
    <Accordion
        active={false}
        title="FACTORES DE RIESGO"
        content='La degeneración macular está asociada a la EDAD. Es el principal factor de riesgo. Otros factores implicados serían:
        <br><br>
        <ul><li>La raza blanca en las formas más graves
        </li>
        <li>Antecedentes familiares
        </li>
        <li>Sexo femenino
        </li>
        <li>Tabaco
        </li>
        <li>La obesidad está implicada en la progresión de formas leve a formas más graves
        </li>
        <li>Enfermedades cardiovasculares, radiación solar, color del iris, niveles de estrógenos, hipertensión arterial…</li></ul>'
    />
     <Accordion
        active={false}
        title="CONSEJOS"
        content='<ul><li>En primer lugar, y por la importancia de su detección precoz, es recomendable la visita periódica a su MÉDICO OFTALMÓLOGO. Él le informará de cómo comprobar su visión, mediante Test Amsler, en casa de forma periódica y otros consejos como:
        </li>
        <li>Dejar de fumar
        </li>
        <li>Control de factores de riesgo cardiovascular, hipertensión arterial, colesterol...
        </li>
        <li>Dieta pobre en grasas, con alimentos ricos en antioxidantes ( verdura, fruta…)
        </li>
        <li>Ejercicio regular
        </li>
        <li>Gafas de filtro ultravioleta 100%</li></ul>'
    />
     <Accordion
        active={false}
        title="TRATAMIENTO"
        content='La DMAE no tiene tratamiento curativo al 100%. Las formas secas no tienen tratamiento, salvo la administración de agentes antioxidantes para intentar desacelerar el proceso. En las formas húmedas existen diversos tratamiento para reducir o enlentecer la pérdida, y en algunos casos mejorar, la agudeza visual.
        <br><br>
        Entre estos últimos se encuentra la fotocoagulación láser, la terapia fotodinámica y los fármacos antiangiogénicos y/o antinflamatorios de administración endocular.'
    /></div>

export { degeneracionAccordion };