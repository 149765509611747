import React from 'react';
import './IndexPage.scss';
import Maps from '../../components/maps/Maps';
import Us from '../../components/Us/Us';
import Video from '../../components/videoComponent/VideoComponent';
import Banner from '../../components/banner/Banner';
import Carousel from '../../components/carousel/Carousel';
import Appointment from './../../components/appointment/Appointment';


function App() {
  return (
    <div className="App">
      {/* <Video/> */}
      <Banner />
      <Appointment />
      <Us />
      <Carousel />
      <Maps />     
    </div>
  );
}

export default App;
