import React from 'react';

const EcografiaContent = <div className="study-content">
<h1>Ecografía</h1>
<div className="sep"></div>

Su uso es imprescindible en multitud de situaciones clínicas. La Ecografía identifica cualquier alteración anatómica con un tamaño mínimo situada en la cavidad vítrea, en la retina o en la capa inferior a ella (coroides), así como en los tejidos blandos de la órbita (tejido adiposo, músculos oculomotores) o en el nervio óptico. 
<br></br><br></br>
Se realiza estando el paciente normalmente con los ojos cerrados. Se apoya suavemente el transductor con un gel ecográfico sobre los párpados y se le pide al paciente que mueva los ojos en diferentes posiciones. 

<h4>¿En que casos  concretos puede ser de utilidad la ecografía ocular?</h4>

<ul>
<li>Desprendimiento de retina </li>
<li>Alteraciones del nervio óptico (inflamaciones, tumores)
</li>
<li>Alteraciones de los músculos oculomotores (miositis, tumores, infecciones)
</li>
<li>Alteraciones vítreas</li>
<li>Tumores orbitarios, de nervio óptico o de coroides</li> 
<li>Cuando las opacidades de la córnea impiden una buena exploración de las zonas más profundas del globo ocular.
</li>
<li>Alteraciones orbitarias
</li>
<li>Sospecha de cuerpo extraño intraocular traumático
</li>
</ul>

<div className="study-img-cont">
<img src={require("../../assets/images/ecografo.jpg")}></img>
</div>

 
 </div>   

export { EcografiaContent };