import React from "react";
import "./Us.scss";

const Us = () => {
  return (
    <div className="Us-container container">
      <h2>¿Quiénes somos?</h2>
      <div className="sep"></div>
      <div className="Info-container">
        <div>
          <p>
            El centro Oftalmológico Dr Morbelli es una institución creada para brindar atención médica oftalmológica del más alto nivel.
          </p>
          <p>
            Desde que abrió sus puertas en  abril de 1991, contamos con un plantel médico en permanente formación y actualización, apoyado por personal asistencial y administrativo profesional, absolutamente dedicado a brindar la mejor atención a nuestros pacientes.</p>          
          <p>
            Hemos mantenido un equipamiento en permanente actualización, acorde a las prácticas diagnósticas y de tratamiento clínico quirúrgico más completas y avanzadas de la especialidad.
          </p>
          <p>
            Nuestro compromiso permanente es brindar la atención necesaria para lograr mantener la salud visual de nuestros pacientes y contribuir a mejorar su calidad de vida frente a los nuevos desafíos que la actualidad nos impone.
          </p>
        </div>
        <div>
          <img src={require("../../assets/images/aniversariologo.jpg")} alt="Logo"></img>
        </div>
      </div>
    </div>
  );
};

export default Us;
