import React from "react";
import "./MedicCard.scss";
import LazyLoad from 'react-lazyload';

function MedicCard(props) {
  const { title, doctor, speciality, img } = props;

  const Title = () => {
    if (title) {
      return (
        <div>
          <h3>{title}</h3>
          <div className="sep"></div>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="cards-general">
      <Title></Title>
      <LazyLoad height={200} offset={100} once>       
        <div className="image">
        <img src={img} alt={doctor}></img>
        </div>        
        <h4>{doctor}</h4>
        <p>{speciality}</p>        
      </LazyLoad>
    </div>
  );
}

export default MedicCard;
