import React from 'react';

const HRTContent = <div className="study-content">
<h1>HRT(Laser confocal de barrido)</h1>
<div className="sep"></div>

La mayoría de la gente toma como sinonimo la enfermedad ocular llamada glaucoma con presión ocular alta. Es importante entender que el glaucoma no es simplemente una enfermedad de alta presión intra-ocular, sino más bien, una enfermedad más compleja del nervio óptico. En las primeras etapas del glaucoma no presenta síntomas y es comprensible que cualquier condición que carece de síntomas tiene un gran riesgo potencial. En el glaucoma el borde conductor de visión del nervio óptico, llamada la capa de fibra nerviosa, se daña gradualmente, lo que resulta en una disminución de la información visual que abandona el ojo hasta el cerebro. Ahora entendemos que la medición de la presión ocular es sólo una pequeña parte de los datos recogidos por los médicos para evaluar el cuadro total en lo que se refiere al glaucoma. Hasta ahora las pruebas utilizadas para diagnosticar el glaucoma y establecer la necesidad de tratamiento se basan en la presión ocular y pérdida de la visión documentada y un diagnóstico sólo era posible después de que la pérdida de visión ya había tenido lugar.
<br></br><br></br>
Actualmente, los métodos modernos de estudios, nos permiten evaluar la capa de fibras nerviosas del nervio óptico. El Heidelberg Retinal tomógrafo (HRT) es un sistema que combina una cámara de escaneo láser y un software especializado que evalúa el nervio óptico, es decir, nos permite comprender la evolución de la afectación del nervio óptico en relación con el glaucoma y otras enfermedades oculares mucho antes de que la pérdida irreversible de la visión se lleve a cabo. Utiliza información en tiempo real del ojo vivo para el estudio y análisis inmediato. Además, cada imagen capturada se compara la utilización de una base de datos de los resultados normales de la edad del paciente. 


<div className="study-img-cont">
<img className="HRT" src={require("../../assets/images/HRT.jpg")}></img>
<img className="HRT" src={require("../../assets/images/Informe HRT.jpg")}></img>
</div>

 
 </div>   

export { HRTContent };