import React, { useEffect, useState, useRef } from "react";
import {NavLink} from "react-router-dom";

import './dropdown.scss';

const Dropdown = ({ value, options, id}) => {
  const node = useRef();

  const [open, setOpen] = useState(false);

  const handleClick = e => {
    if (node.current.contains(e.target)) {       
      // inside click      
      return;
    }
    document.getElementById(id).classList.remove('active'); 
    // outside click
    setOpen(false);
  };  

  const handleItemClick = e => {
    document.getElementById(id).classList.add('active');
    setOpen(false);
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <div ref={node} className="dropdown" onMouseOver={e => setOpen(true)} onMouseLeave={e => setOpen(false)} >
      <a className="dropdown-toggler" onClick={e => setOpen(!open)} id={id}>
        {value}
      </a>
      {open && (
        <ul className="dropdown-menu">
          {options.map(opt =>{
              let redirectRoute = opt.path;               
              return (               
                <NavLink className="dropdown-menu-item" onMouseOver={e => setOpen(true)} activeClassName='active' to={redirectRoute}
                 onClick={e => handleItemClick()} key={redirectRoute}>
                    {opt.text}
                </NavLink>
              )
          })}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
