import React from "react";
import "./Banner.scss";
import ImageGallery from 'react-image-gallery';
import LazyLoad from 'react-lazyload';

import bannerImage01 from '../../assets/images/banner/banner01.jpg';
import bannerImage03 from '../../assets/images/banner/banner03.jpg';
import bannerImage04 from '../../assets/images/banner/banner04.jpg';

const Carousel = () => {
  const images = [
    {
      original: bannerImage01,      
    },    
    {
      original: bannerImage03,     
    },
    {
        original: bannerImage04,     
      },
  ];
  
  return (
    <LazyLoad height={200} offset={100} once>
    <div className="banner-container">
      {/* <p>CAROUSEL</p> */}
      
      <ImageGallery items={images} showFullscreenButton={false} showPlayButton={false} 
      showThumbnails={false} autoPlay={true} slideInterval={5000}/>

   

    </div>
    </LazyLoad>
  );
};

export default Carousel;
