import React from "react";
import "./FlipMedicCard.scss";

function FlipMedicCard(props) {
  const { doctor, speciality, img } = props;


   return (  


    <div className="flip-card">
    <div className="flip-card-inner">
      <div className="flip-card-front">
        <h1>{doctor}</h1>
        <img src={img} alt={doctor}></img>
      </div>
      <div className="flip-card-back">
        <h1>Especialidad</h1>
        <p>{speciality}</p>
      </div>
    </div>
  </div>

   );
 }

 export default FlipMedicCard;
