import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import './AlertModal.scss';

export default function AlertDialog() {
  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        disableBackdropClick={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{ <div className="warning-icons">
            <img src={require("../../assets/images/warning-logo.svg")} alt="Teléfono"></img>
            <h1 className="important">IMPORTANTE</h1>
            <img src={require("../../assets/images/warning-logo.svg")} alt="Teléfono"></img>
            </div>}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">       
            <span>Los turnos para controles escolares, niños y estudios se otorgarán únicamente por vía telefónica o por WhatsApp</span>
            </DialogContentText>
        </DialogContent>
        <DialogActions>         
          <Button onClick={handleClose} color="primary" autoFocus>
            De Acuerdo
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
