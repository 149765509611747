import React from 'react';
import { NavLink, useLocation } from "react-router-dom";
import {cirugiaAccordion, degeneracionAccordion, corneaAccordion, presbiciaAccordion, cataratasAccordion, glaucomaAccordion, retinaAccordion} from '../../utils/specialities/specialities';
import './SpecialityPage.scss';
import LazyLoad from 'react-lazyload';


function App() {
  const location = useLocation();
  const CurrentAccordion = () => {
    if(location.pathname === "/cirugiaRefractiva"){
      return cirugiaAccordion;
    }
    if(location.pathname === "/degeneracionMaculura"){
      return degeneracionAccordion;
    }
    if(location.pathname === "/cornea"){
      return corneaAccordion;
    }
    if(location.pathname === "/presbicia"){
      return presbiciaAccordion;
    }
    if(location.pathname === "/cataratas"){
      return cataratasAccordion;
    }
    if(location.pathname === "/glaucoma"){
      return glaucomaAccordion;
    }
    if(location.pathname === "/retina"){
      return retinaAccordion;
    }
    return null
  }

  return (
    <div className="container">
      <div className="speciality-cont">      
      <div className="speciality-nav">
        <ul>
          <li>
            <NavLink exact
              activeClassName='active' to="/cirugiaRefractiva">Cirugia Refractiva</NavLink>
          </li>
          <li>
            <NavLink activeClassName='active' to="/degeneracionMaculura">Degeneracion Macular</NavLink>
          </li>
          <li>
            <NavLink activeClassName='active' to="/cornea">Cornea y superficie Ocular</NavLink>
          </li>
          <li>
            <NavLink activeClassName='active' to="/presbicia">Presbicia</NavLink>
          </li>
          <li>
            <NavLink activeClassName='active' to="/cataratas">Cataratas</NavLink>
          </li>
          <li>
            <NavLink activeClassName='active' to="/glaucoma">Glaucoma</NavLink>
          </li>
          <li>
            <NavLink activeClassName='active' to="/retina">Retina Y Vitreo</NavLink>
          </li>
        </ul>
      </div>
      <LazyLoad height={200} offset={100} once>
      <div className="speciality-info">
        <CurrentAccordion></CurrentAccordion>
      </div>
      </LazyLoad>
      </div>
    </div>
  );
}

export default App;
