import React from 'react';
import { NavLink, useLocation } from "react-router-dom";
import {OCTContent, HRTContent, biometriaContent, CVCContent, EcografiaContent, EndotelioContent} from '../../utils/studies/studies';
import './StudyPage.scss';
import LazyLoad from 'react-lazyload';


function App() {
  const location = useLocation();
  const CurrentContent = () => {
    if(location.pathname === "/OCT"){
      return OCTContent;
    } 
    if(location.pathname === "/HRT"){
      return HRTContent;
    } 
    if(location.pathname === "/biometria"){
      return biometriaContent;
    }  
    if(location.pathname === "/CVC"){
      return CVCContent;
    }  
    if(location.pathname === "/ecografia"){
      return EcografiaContent;
    } 
    if(location.pathname === "/endotelio"){
      return EndotelioContent;
    }
    return null
  }

  return (
    <div className="container">
      <div className="study-cont">      
      <div className="study-nav">
        <ul>
          <li>
            <NavLink exact
              activeClassName='active' to="/OCT">OCT (tomografia de coherencia Optica)</NavLink>
          </li>
          <li>
            <NavLink activeClassName='active' to="/HRT">HRT (Laser confocal de barrido)</NavLink>
          </li>
          <li>
            <NavLink activeClassName='active' to="/biometria">Biometria óptica y topografía</NavLink>
          </li>
          <li>
            <NavLink activeClassName='active' to="/CVC">CVC (campo visual Computarizado)</NavLink>
          </li>  
          <li>
            <NavLink activeClassName='active' to="/ecografia">Ecografía</NavLink>
          </li>
          <li>
            <NavLink activeClassName='active' to="/endotelio">Recuento endotelial</NavLink>
          </li>             
        </ul>
      </div>
      <LazyLoad height={200} offset={100} once>
      <div className="speciality-info">
        <CurrentContent></CurrentContent>
      </div>
      </LazyLoad>
      </div>
    </div>
  );
}

export default App;
