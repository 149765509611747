import {
  BrowserView,
  MobileView,
} from "react-device-detect";

import React from "react";
import "./MedicsPage.scss";
import MedicCard from "../../components/medicCard/MedicCard";
import FlipMedicCard from "../../components/flipMedicCard/FlipMedicCard";

const MedicsPage = () => {
  return (
    <div className="container">
    <div className="medics-container"> 

    <BrowserView>
    <div className="directivos-flip-card">
      <div className="directivos-cont">
        <div>
          <h1>Director médico</h1>
          <div className="sep"></div>
        </div>
        <FlipMedicCard 
          doctor="Dr. Héctor Mario Morbelli"
          img={require("../../assets/images/doctores/Dr-Hector-Morbelli.jpg")}
          speciality="Cornea, Cataratas, Cirugía Refractiva"/>
      </div>

      <div className="directivos-cont">
        <div>
          <h1>Subdirectora médica</h1>
          <div className="sep"></div>
        </div>
        <FlipMedicCard 
          doctor="Dra. Laura Garassino"
          img={require("../../assets/images/doctores/Dra-Laura-Garassino.jpg")}
          speciality="Oftalmología general, Estética Periocular y Botox"/>
      </div>

    </div>

    <div>
      <div className="equipo-cont">
          <div>
            <h1>Cuerpo médico</h1>
            <div className="sep"></div>
          </div>

          <div className="equipo-cards-cont">
          <FlipMedicCard 
            doctor="Dr. Cristian Acquisto"
            img={require("../../assets/images/doctores/Dr-Cristian-Acquisto.jpg")}
            speciality="Oftalmología General"/>

          <FlipMedicCard 
            doctor="Dra. Mariela Baleiron"
            img={require("../../assets/images/doctores/Dra-Mariela-Baleiron.jpg")}
            speciality="Oftalmología General"/>
          
          <FlipMedicCard 
            doctor="Dra. Maria De Las Mercedes Miralles"
            img={require("../../assets/images/doctores/Dra-Maria-De-Las-Mercedes-Miralles.jpg")}
            speciality="Oftalmología General"/>

          <FlipMedicCard 
            doctor="Dr. Agustin Morbelli"
            img={require("../../assets/images/doctores/Dr-Agustín-Morbelli.jpg")}
            speciality="Oftalmología General, cataratas, cirugía Refractiva"/>

          <FlipMedicCard 
            doctor="Dr. Eduardo Nelli"
            img={require("../../assets/images/doctores/Dr-Eduardo-Nelli.jpg")}
            speciality="Oftalmología General, cataratas"/>

          <FlipMedicCard 
            doctor="Dra. Camila Oraindi"
            img={require("../../assets/images/doctores/Dra-Camila-Oraindi.jpg")}
            speciality="Oftalmología General"/>

          <FlipMedicCard 
            doctor="Dra. Sabrina Peirano"
            img={require("../../assets/images/doctores/Dra-Sabrina-Peirano.jpeg")}
            speciality="Oftalmología general y oftalmopediatria. Estética Periocular"/>

          <FlipMedicCard 
            doctor="Dra. Florencia Ravea"
            img={require("../../assets/images/doctores/Dra-Florencia-Ravea.jpg")}
            speciality="Oftalmología General"/> 
          
          <FlipMedicCard 
            doctor="Dra. Vanina Scofano"
            img={require("../../assets/images/doctores/Dra-Vanina-Scofano.jpg")}
            speciality="Oftalmología general"/> 

          <FlipMedicCard 
            doctor="Dra. Marisol Vazquez"
            img={require("../../assets/images/doctores/Dra-Marisol-Vazquez.jpg")}
            speciality="Oftalmología General, Estrabismo"/> 
          
          </div>
          
        </div>
    </div>
    
    </BrowserView>





    <MobileView>
    
      <div className="directivos-card">
        <MedicCard
          title="Director médico"
          doctor="Dr. Héctor Mario Morbelli"
          img={require("../../assets/images/doctores/Dr-Hector-Morbelli.jpg")}
          speciality="Cornea, Cataratas, Cirugía Refractiva"
        />

        <MedicCard
          title="Subdirectora médica"
          doctor="Dra. Laura Garassino"
          img={require("../../assets/images/doctores/Dra-Laura-Garassino.jpg")}
          speciality="Oftalmología general, estética Periocular, Botox"
        />
      </div>
      <div className="equipo-card">
        <div>
          <h3>Equipo médico</h3>
          <div className="sep"></div>
        </div>
        <div className="card-general">

        <MedicCard
            doctor="Dr. Cristian Acquisto"
            img={require("../../assets/images/doctores/Dr-Cristian-Acquisto.jpg")}
            speciality="Oftalmología General"
          />

          <MedicCard
            doctor="Dra. Mariela Baleiron"
            img={require("../../assets/images/doctores/Dra-Mariela-Baleiron.jpg")}
            speciality="Oftalmología General"
          />
          
          <MedicCard
            doctor="Dra. Maria De Las Mercedes Miralles"
            img={require("../../assets/images/doctores/Dra-Maria-De-Las-Mercedes-Miralles.jpg")}
            speciality="Oftalmología General"
          />
        
          <MedicCard
            doctor="Dr. Agustin Morbelli"
            img={require("../../assets/images/doctores/Dr-Agustín-Morbelli.jpg")}
            speciality="Oftalmología General, cataratas, cirugía Refractiva"
          />
          
          <MedicCard
            doctor="Dr. Eduardo Nelli"
            img={require("../../assets/images/doctores/Dr-Eduardo-Nelli.jpg")}
            speciality="Oftalmología General, cataratas"
          />
          
          <MedicCard
            doctor="Dra. Camila Oraindi"
            img={require("../../assets/images/doctores/Dra-Camila-Oraindi.jpg")}
            speciality="Oftalmología General"
          />

          <MedicCard
            doctor="Dra. Sabrina Peirano"
            img={require("../../assets/images/doctores/Dra-Sabrina-Peirano.jpeg")}
            speciality="Oftalmología general y oftalmopediatria. estética Periocular"
          />

          <MedicCard
            doctor="Dra. Florencia Ravea"
            img={require("../../assets/images/doctores/Dra-Florencia-Ravea.jpg")}
            speciality="Oftalmología General"
          />

          <MedicCard
            doctor="Dra. Vanina Scofano"
            img={require("../../assets/images/doctores/Dra-Vanina-Scofano.jpg")}
            speciality="Oftalmología general"
          />

          <MedicCard
            doctor="Dra. Marisol Vazquez"
            img={require("../../assets/images/doctores/Dra-Marisol-Vazquez.jpg")}
            speciality="Oftalmología General, Estrabismo"
          />

        </div>
      </div>
     
    </MobileView>
    </div>   
    </div>    
  );
};

export default MedicsPage;
