import React from "react";
import "./Maps.scss";

const Maps = () => {
  return (
    <div className="Mapa">
      <div className="container">
        <h2>Nuestra ubicación</h2>
        <div className="sep"></div>
        <p>Carlos Pellegrini 399, Lomas de Zamora B1832, Buenos Aires</p>
        <p>Atención al paciente: (54 11) 4292 - 2776 | 4245 - 1717 / 5433</p>
        <p>
          Horarios de atención: Lunes a viernes de 09 a 20. Sábados de 09 a 13
        </p>
      </div>
      <div className="maps container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3277.968160196238!2d-58.39929728518977!3d-34.75639487309848!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcd295d51283bd%3A0x830bc66b80a79025!2sCarlos%20Pellegrini%20399%2C%20B1832%20BNH%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses!2sar!4v1603653172619!5m2!1ses!2sar"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </div>
    </div>
  );
};

export default Maps;
