import React from 'react';

const CVCContent = <div className="study-content">
<h1>CVC (campo visual Computarizado)</h1>
<div className="sep"></div>

Por medio del examen de campo visual computarizado se puede detectar la disminución o pérdida de la visión periférica y se obtiene un mapa de dicha pérdida, el cual se puede utilizar para diagnosticar la causa de la misma.  Es un examen obligatorio en toda consulta oftalmológica debido a que:


<ul>
<li>Nuestros ojos no ven únicamente lo que esta localizado al frente, tienen una visión periférica que debe ser evaluada.  Si se evaluará únicamente la capacidad para ver de frente estaría dejándose de lado el mayor porcentaje de nuestra capacidad visual.
</li>
<li>El glaucoma es una de las enfermedades que mas ceguera causan en el mundo, el examen de campo visual es fundamental para el diagnóstico oportuno de esta enfermedad.
La evaluación de campo visual también puede detectar enfermedades neurológicas como adenomas de hipófisis, otros tumores y alteraciones cerebrales, asi como alteraciones a nivel de la macula que no podrían ser detectas de otra forma.
</li></ul>

<div className="study-img-cont">
<img className="biometria" src={require("../../assets/images/CVC.jpg")}></img>
<img className="biometria" src={require("../../assets/images/Informe CVC.jpg")}></img>
</div>

 
 </div>   

export { CVCContent };