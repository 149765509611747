import Accordion from '../../components/accordion/Accordion';
import React from 'react';

const presbiciaAccordion = <div className="accordion_cont"><h1>Presbicia</h1>
    <Accordion
        active={true}
        title="¿QUE ES?"
        content='La <b>presbicia o vista cansada</b> no es más que una <b>disfunción de una lente intraocular natural que se encuentra en nuestro ojo</b>: el cristalino. El cristalino es el responsable de realizar de manera automática el enfoque del ojo para cualquier distancia (acomodación). <b>Es como el sistema de "autofocus" de las cámaras</b> de vídeo y fotografía. Aplicado a nuestros ojos, el mecanismo dinámico de nuestro sistema visual de enfocar a diferentes distancias con gran facilidad, en condiciones normales, es lo que se llama acomodación.
        <br><br>
        A partir de los 40-45 años, el cristalino pierde progresivamente su elasticidad natural, lo que dificulta el enfoque de las imágenes y provoca <b>falta de nitidez en la visión cercana</b>.'
    />
    <Accordion
        active={false}
        title="UN SINTOMA MUY COMUN"
        content='Los pacientes notan, por ejemplo, que el <b>material de lectura lo tienen que colocar a mayor distancia para poder enfocarlo correctamente</b>. Hay que tener en consideración que no es una anomalía de la visión o una patología, puesto que es común para todos los individuos, teniendo su origen en un deterioro fisiológico.
        <br><br>
        Asociado a la <b>dificultad progresiva de enfocar objetos cercanos</b>, el cristalino también pierde la capacidad de compensar defectos ópticos antiguos (hipermetropías y astigmatismos), de forma que simultáneamente se puede producir un empeoramiento de la visión lejana.
        <br><br>
        El fallo de enfoque de cerca <b>va aumentando progresivamente hasta los 60 años</b> de edad cuando se estabiliza en un tope. Hasta entonces, cada dos o tres años hay que ir ajustando la graduación de cerca para un correcto enfoque.'
    />
    <Accordion
        active={false}
        title="TIPOS DE CIRUGIAS"
        content='Uno de los grandes retos de la Oftalmología avanzada es la <b>corrección de la presbicia</b>. ¿Cómo corrgirlo? Nuestros estudios personalizados nos permiten encontrar la mejor solución para cada paciente dependiendo de la <b>edad</b> del paciente, de su <b>graduación</b> de lejos y de cerca y de las <b>características</b> de sus ojos.
        <br><br>
        <b>LENTES PREMIUM: cirugía intraocular con implantación de lentes multifocales y acomodativas:</b>
        <br><br>
        Esta técnica está basada en el intercambio del cristalino por una nueva <b>lente intraocular de última generación, o Lente Premium</b>, con excelentes resultados en altos defectos refractivos sin necesidad de utilizar gafas de cerca o progresivas permitiendo:
        <ul>
        <li><b>Visión lejana:</b> por ejemplo una excelente agudeza visual para la conducción o ver la televisión cómodamente.
        </li>
        <li><b>Visión cercana:</b> por ejemplo una lectura nítida sin lentes, así como una visión a distancias intermedias como podría ser el uso de la computadora.
        </li>
        <li><b>Versátiles:</b> permiten la corrección de varios defectos refractivos como la miopía o hipermetropía, el astigmatismo junto con la vista cansada.
        </li></ul>
        Cada <b>lente está adaptada a cada ojo</b> y tiene un diseño específico, eso encarece el costo pero, por otra parte, hay que sopesar el <b>ahorro que se genera en las gafas que se van a dejar de pagar el resto de la vida</b>. Las lentes estándares monofocales no permiten obtener este resultado, puesto que están diseñadas para corregir una sola distancia, con lo cual el paciente continúa dependiendo de los lentes tras este tipo de intervención.
        
        <h4>Tipos de Lentes</h4>
        
        Según las exigencias visuales de cada paciente:  
        <ul>        
        <li>Lentes multifocales bifocales</li>
        
        <li>Lentes multifocales trifocales</li>
        
        <li>Lentes multifocales bifocales tóricas (con astigmatismo)</li>
        
        <li>Lentes multifocales trifocales tóricas (con astigmatismo)</li>
        
        <li>Lentes tóricas</li>'
    />
    <Accordion
        active={false}
        title="CONSEJOS"
        content='En primer lugar, y por la importancia de su detección precoz, es recomendable la visita periódica a su MÉDICO OFTALMÓLOGO. Él le informará de cómo comprobar su visión, mediante Test Amsler, en casa de forma periódica y otros consejos como:

        Dejar de fumar
        
        Control de factores de riesgo cardiovascular, hipertensión arterial, colesterol...
        
        Dieta pobre en grasas, con alimentos ricos en antioxidantes ( verdura, fruta…)
        
        Ejercicio regular
        
        Gafas de filtro ultravioleta 100%'
    />
    <Accordion
        active={false}
        title="PREGUNTAS FRECUENTES"
        content='<h4>¿La vista cansada o presbicia la padecen todos los seres humanos?</h4>

        Efectivamente, al <b>100 % de los seres humanos</b>, aunque los miopes se verán siempre menos afectados
        
        <h4>¿Cuándo aparece la presbicia? ¿Se detiene en algún momento de la vida?</h4>
        
        Suele aparecer en la cuarta década de la vida y suele detener su evolución al término de la sexta
        
        <h4>¿Cuáles son las mejores soluciones para solventarla?</h4>
        
        Desde el apoyo en unas simples lentes hasta recurrir a un procedimiento quirúrgico.
        
        <h4>¿La solución quirúrgica es definitiva o puede ser aumentando con la edad?</h4>
        
        Los procesos corneales ayudarán a paliar el defecto pero se verá afectado por el paso de los años. En cambio el implante de lentes de alta tecnología harán que el proceso sea definitivo.
        
        <h4>¿Cómo afecta a la visión?</h4>
        
        Se ver afectada la visión de cerca (leer, escribir...) inicialmente y posteriormente también la visión intermedia ( ordenador...).
        
        <h4>¿La cirugía permite corregirla de forma definitiva?</h4>
        
        SI, se puede corregir de forma definitiva, según el tipo de cirugía y el caso concreto.'
    /></div>

export { presbiciaAccordion };