import React from 'react';

const biometriaContent = <div className="study-content">
<h1>Biometria óptica y topografía</h1>
<div className="sep"></div>

La cirugía de cataratas es uno de los procesos quirúrgicos más habituales y precisos a nivel mundial. Gracias a la nueva tecnología, hoy en día es posible obtener resultados visuales muy predecibles tras la cirugía mediante el cálculo exacto de lentes intraoculares. La herramienta que se utiliza para obtener estos resultados óptimos es la Biometría Óptica.
<br></br><br></br>
En nuestro centro disponemos de un biometro óptico de ultima tecnologia, una herramienta vital para la correcta selección de la lente Premium que queremos implantar.
<br></br><br></br>
Cada ojo es diferente y los cálculos deben realizarse de una forma muy personalizada. Por ese motivo este Biómetro consigue medir todos los parámetros necesarios con el fin de obtener resultados individualizados:

<ul>
<li>Longitud axial</li>
<li>Amplitud de la cámara anterior del ojo</li>
<li>Radios corneales</li>
<li>Diámetro pupilar</li>
<li>Distancia blanco-blanco (diámetro corneal)</li>
</ul>

Posee un sistema de medición guiado por interferometría óptica: se proyecta un haz de luz que atraviesa todo el ojo y detecta todas las estructuras oculares, hasta llegar a la última capa de la retina. De esta forma, se consigue la longitud axial, una de las medidas más importantes para elegir adecuadamente la lente intraocular. Además, consigue analizar hasta 1000 puntos corneales en 3 mm de diámetro, con el fin de obtener los radios de la manera más fiable posible.

<h4>Topografia</h4>
El biometro ofrece otra característica fundamental en el análisis ocular: la topografía corneal. Se trata del estudio de la superficie corneal obtenido a través de una serie de haces luminosos proyectados en la córnea: los “discos de Plácido”. Gracias a la luz reflejada es posible averiguar las posibles irregularidades presentes en la córnea.


<div className="study-img-cont">
<img className="biometria" src={require("../../assets/images/Lentes intraoculares BIOMETRO.jpg")}></img>
<img className="biometria" src={require("../../assets/images/Biometro - topografo.jpg")}></img>
</div>

 
 </div>   

export { biometriaContent };