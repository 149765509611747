import Accordion from '../../components/accordion/Accordion';
import React from 'react';

const retinaAccordion = <div className="accordion_cont"><h1>Retina y Vitreo</h1>
    <Accordion
        active={true}
        title="¿QUE ES LA RETINA Y EL VITREO?"
        content='El <b>vítreo</b> es una sustancia gelatinosa situada entre el cristalino y la retina que mantiene la forma del ojo . El deterioro de este gel vítreo da lugar a condensaciones o aglutaciones que pueden proyectar sombra sobre la retina y dar lugar a la conocida visión de <b>“moscas volantes”</b>.
        <br><br>
        La <b>retina</b> es el órgano sensorial del ojo, donde se proyectan las imágenes percibidas, se procesan en forma de impulso nervioso que sale por el nervio óptico. Es como “la película de una cámara”. Constituye la capa mas interna en la parte posterior del ojo. Las imágenes penetran a través de nuestra pupila, pasan por el cristalino y son enfocadas por la retina. Esta las convierte en señales eléctricas y las envía a través del nervio óptico al cerebro.
        <br><br>
        Hay múltiples patologías que afectan al vítreo, como los desprendimientos de vítreo, y las pérdidas de su transparencia como las hemorragias vítreas. Por su parte, la patologias mas frecuentes de la retina son la degeneración macular, la retinopatía diabética y las trombosis venosas. Tiene alta frecuencia el desprendimiento de retina, la membrana epirretiniana y el agujero macular. Siendo estas ultimas de resolución con cirugia.'
    />
    <Accordion
        active={false}
        title="SINTOMAS Y DIAGNOSTICO"
        content='Lo más frecuente es la aparición de <b>moscas volantes, sombras o destellos</b>, así como la visión borrosa. También la distorsión o metamorfopsia. Todos estos síntomas pueden indicar la presencia de una patología vitreorretiniana.
        <br><br>
        Por su parte, las causa de un <b>despredimiento de retina</b> es con mucha frecuencia el desprendimiento vítreo que se produce de por causas degenerativas o por traumatismos, como por ejemplo hoy en día los que se producen en juegos de pelota como el Squash, Paddle , Tenis o incluso en futbol, así como por patologias derivadas de pacientes miopes (destacar el control anual que deben de tener), o por inflamaciones y retinopatías diabéticas descontroladas. <b>La única solución es mediante cirugía</b>.
        <br><br>
        El <b>diagnóstico</b> suele realizarse en consulta con una exploración básica, aunque puede completarse con ecografía, angiografía u OCT (tomografía de coherencia óptica).'
    />
    <Accordion
        active={false}
        title="TRATAMIENTO"
        content='En ocasiones los síntomas pueden ser pasajeros y mejorar con el tiempo (por ejemplo, los desprendimientos del vítreo por la edad). En otras ocasiones, se necesita tratamiento sistémico o intraocular. También la fotocoagulación con láser puede tratar desgarros retinianos en ausencia de desprendimiento de retina.

        <h4>Vitrectomia</h4>
        
        La vitrectomía y la cirugía escleral o retinopexia neumática se pueden utilizar para solucionar desprendimientos de retina. La vitrectomía consiste en una cirugía con pequeña incisión a nivel del vítreo, usando material especialmente desarrollado para dicha técnica. Se puede realizar frecuentemente de forma ambulante y con anestesia local, actualmente con buenos resultados en muchos de los casos.
        
        <h4>Fotocoagulacion Laser</h4>
        
        Otra de las novedades es un nuevo láser para el tratamiento de las enfermedades de la retina que esta diseñado con unos patrones especificos para cada enfermedad realizando así un tratamiento mas confortable con mínimas molestias y mas rápido, en el caso de necesitar fotocoagular partes de la retina.'
    />
    <Accordion
        active={false}
        title="PREGUNTAS FRECUENTES"
        content='<h4>¿Los desprendimientos de retina y de vítreo son lo mismo?</h4>

        El desprendimiento de vítreo puede desembocar en desprendimiento de retina en un porcentaje pequeño de casos, lo que obliga a revisar al paciente que lo presenta. Pero en sí, la mayoría de las veces el desprendimiento del vítreo, muy frecuente con la edad y en pacientes miopes e intervenidos de catarata, no suele requerir cirugía. Por el contrario, el desprendimiento de retina es un problema ocular serio, que puede conducir a la ceguera en ausencia de tratamiento.
        
        <h4>¿Se curan los desprendimientos de retina?</h4>
        
        Con las técnicas actuales se consigue reaplicar la retina en porcentajes altos, aunque a veces es necesaria una reintervención. La función retiniana puede recuperarse si el tiempo de evolución del desprendimiento es corto. Pero a día de hoy, sigue resultando un problema serio.
        
        <h4>¿Se puede evitar el desprendimiento de retina?</h4>
        
        No hay un tratamiento 100% efectivo para la prevención del desprendimiento de retina. El laser puede reducir ese riesgo, pero no eliminarlo. Lo más adecuado es que el paciente conozca los síntomas y acuda caso de presentarlos (moscas, sombras, destellos y pérdida visual)
        
        <h4>¿Puede afectar al otro ojo?</h4>
        
        Mucha de la patología ocular, especialmente los desprendimientos de retina, pueden presentarse en el ojo contralateral. Es necesario explorar ambos ojos.
        
        <h4>¿Cuánto tiempo de recuperación?</h4>
        
        En la cirugía retiniana, el tiempo de recuperación es mayor que en la cirugía del polo anterior, especialmente la catarata. A veces se requiere mantener alguna posición especial, como por ejemplo con los gases intraoculares en los agujeros maculares. Suelen pasar algunas semanas hasta la recuperación visual completa, incluso puede prolongarse ésta durante los primeros meses en determinados casos como el edema macular traccional.
        
        <h4>¿Qué técnicas quirúrgicas existen para su solución?</h4>
        
        La cirugía de las patologías retinianas ha supuesto en los últimos tiempos una revolución en cuanto a las técnicas utilizadas. La vitrectomía, que es como se llama esta técnica, hoy en dia es el método quirúrgico elegido en la mayoría de los casos. La cirugía microincisional favorece el menor daño al globo ocular, así como recuperaciones más cortas.'
    />   
    </div>

export { retinaAccordion };