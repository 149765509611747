import React from "react";
import "./Carousel.scss";
import ImageGallery from 'react-image-gallery';
import LazyLoad from 'react-lazyload';

const Carousel = () => {
  const images = [
    {
      original: require('../../assets/images/Archivo_002.jpeg'),
      thumbnail:require('../../assets/images/Archivo_002.jpeg'),
    },
    {
      original: require('../../assets/images/Archivo_000.jpeg'),
      thumbnail: require('../../assets/images/Archivo_000.jpeg'),
    },
    {
      original: require('../../assets/images/Archivo_001.jpeg'),
      thumbnail:require('../../assets/images/Archivo_001.jpeg'),
    },
  ];
  
  return (
    <LazyLoad height={200} offset={100} once>
    <div className="Carousel-container">
      {/* <p>CAROUSEL</p> */}
      
      <ImageGallery items={images} showFullscreenButton={false} showPlayButton={false}/>

   

    </div>
    </LazyLoad>
  );
};

export default Carousel;
