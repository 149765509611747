import React from "react";
import "./Footer.scss";
import LazyLoad from 'react-lazyload';

function Footer() {
  function FooterComponent() {
    return (
      <div className="footer-container">
        <div className="container">
          <div className="info">
            <div>
              <img
                src={require("../../assets/images/clock.svg")}
                alt="Horarios de Atención"
              ></img>
              <p>
                Horarios de atención: Lunes a viernes de 09 a 20. Sábados de 09
                a 13
              </p>
            </div>
            <div>
              <img
                src={require("../../assets/images/maps-and-flags.svg")}
                alt="Dirección"
              ></img>
              <p>Dirección: Carlos Pellegrini 399, Lomas de Zamora B1832, Buenos Aires</p>
            </div>
            <div>
              <img src={require("../../assets/images/phone.svg")} alt="Teléfono"></img>
              <p>
                Atención al paciente: (54 11) 4292 - 2776 | 4245 - 1717 / 5433
              </p>
            </div>
            <div>
            <div className="info-wpp">
                <a
                  href="https://api.whatsapp.com/send?phone=+541153761195"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require("../../assets/images/whatsapp.svg")}
                    alt="Whatsapp"
                  ></img>
                  <p>Turnos por Whatsapp +541153761195</p>
                </a>
              </div>
              </div>
          </div>
          <div className="info-social">
            <div>
              <div>
                <a
                  href="https://www.facebook.com/ojosmorbelli"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require("../../assets/images/facebook.png")}
                    alt="Facebook"
                  ></img>
                  <p>www.facebook.com/ojosmorbelli</p>
                </a>
              </div>
              <div>
                <a
                  href="https://www.instagram.com/ojosmorbelli"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require("../../assets/images/instagram.png")}
                    alt="Instagram"
                  ></img>
                  <p>https://www.instagram.com/ojosmorbelli/</p>
                </a>
              </div>
            </div>
            <div className="copyright">
              <small>&copy; Copyright © CentroOftalmologicoMorbelli 2016</small>
            </div>
          </div>
        </div>        
      </div>
    );
  }

  return <FooterComponent />;
}

export default Footer;
