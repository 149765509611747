import Accordion from '../../components/accordion/Accordion';
import React from 'react';

const cirugiaAccordion = <div className="accordion_cont"><h1>Cirugia Refractiva</h1>
    <Accordion
        active={true}
        title="QUE ES"
        content='<h4>MIOPIA, HIPERMETROPIA Y ASTIGMATISMO</h4> La miopía, la hipermetropía y el astigmatismo son lo que llamamos <b>defectos refractivos</b> que nos impiden ver las imágenes enfocadas. Se dice que un ojo tiene un defecto refractivo cuando la potencia de sus lentes naturales (cristalino + córnea) no se corresponde con la distancia a la que se encuentra la retina, ya sea porque la distancia a esta sea demasiado pequeña (hipermetropía) o demasiado larga (miopía). 
        <br><br>
        Es decir, el ojo, órgano encargado de la visión, <b>realiza una función similar a la de una cámara fotográfica</b>. Dispone de unas lentes encargadas de formar una imagen nítida del mundo exterior (cristalino y córnea), sobre una película sensible (retina) que recibe esta imagen y, a través del nervio óptico, la envía a nuestro cerebro para que la "veamos". Para que esta imagen se vea nítida deberá estar enfocada exactamente sobre la retina. Si esto no ocurre, podemos estar ante un defecto refractivo.'
    />
    <Accordion
        active={false}
        title="TIPOS DE CIRUGIA"
        content='A grandes rasgos, <b>Lasik</b> (láser in situ queratomileusis) es la técnica más extendida actualmente en la práctica de la cirugía refractiva para corregir la miopía, hipermetropía y astigmatismo que no requiere hospitalización. Siempre consta de dos partes: 
    <br><br>
    <ul><li>Primera fase: el cirujano levanta una fina capa del tejido corneal semejante a una lentilla.</li>
    
    <li>Segunda fase: corregimos la graduación moldeando la córnea con un láser Excimer de última generación.</li></ul> 
    
    <h4>¿Cómo es el procedimiento?</h4>
    
    <ul><li><b>Preoperatorio:</b> Antes de realizar esta intervención quirúrgica es necesario llevar a cabo una serie de pruebas que van a determinar si un paciente concreto es o no apto para poderse operar.</li></ul>
 
    Estas mediciones y controles van a determinar el estado en que se encuentra la córnea que va a ser tratada. Puesto que se pretende alterar su forma y su grosor, habrá que analizar la forma que originalmente tiene la córnea.
    <br><br>
    Para ello será imprescindible que retiremos las lentes de contacto con el tiempo suficiente para que la córnea sea normal (las lentes de contacto producen cambios físicos sobre la superficie de la córnea ya sea por el propio roce como por cambios en la hidratación o pequeños edemas superficiales). Normalmente se necesitan aproximadamente entre 10- 15 días sin lente de contacto sobre el ojo a tratar. 
    <br><br>
    Una vez comprobado que la córnea no presenta alteraciones y que no existen enfermedades oculares que contraindiquen la cirugía refractiva (la más evidente sería la presencia de cataratas y glaucoma en estado descompensado), se realiza una topografía corneal computarizada. Esta prueba consiste en realizar un mapa topográfico de la superficie corneal. Una segunda prueba consiste en determinar mediante Ultrasonidos el grosor exacto de la córnea: paquimetría corneal ultrasónica. Puesto que esta va a ser adelgazada, es imprescindible conocer si la córnea es lo suficientemente gruesa. Además de estas dos pruebas se deberá recomprobar la graduación exacta del ojo, ya que será la base del tratamiento a realizar.
    <br><br>
    <p>Fases de la operación:</p>
    <ul>
    <li>El día en que se va a realizar la cirugía es necesario acudir a la clínica acompañado y vistiendo ropa cómoda. No es necesario acudir en ayunas. No debe llevar aplicado maquillaje ni perfumes fuertes.
    </li>
    <li>La cirugía se realiza siempre bajo anestesia tópica, es decir, se realiza una anestesia de superficie mediante la aplicación de un <b>Colirio de Anestésico</b>.
    </li>
    <li>De esta manera, se eliminan las molestias que se pudieran ocasionar durante la cirugía, a la vez que permite una colaboración plena durante el acto quirúrgico necesaria para el centrado correcto en la aplicación del Láser. Como ayuda se administrará unos minutos antes de la intervención un ansiolítico por vía oral.
    </li>
    <li>El paciente se acuesta en la camilla del láser donde se le coloca un separador especial que hace que los ojos permanezcan abiertos durante todo el procedimiento.
    </li>
    <li>Se levanta una fina capa de la córnea (flap) semejante a una lentilla, mediante un microqueratomo de alta precisión, proceso que dura unos <b>15 segundos</b>.
    </li>
    <li>A continuación el láser Excimer actúa sobre las capas profundas de la córnea modificando su curvatura y corrigiendo de este modo el defecto refractivo preexistente. La acción del láser es muy rápida; varía según el defecto a corregir (30 seg. aproximadamente).
    </li>
    <li>Posteriormente, el flap corneal se recoloca y se fija por sí solo, sin necesidad de puntos.
    </li>
    <li><b>Cuidados postoperatorios:</b> Tras la cirugía, el paciente permanecerá en una sala de la clínica con los ojos cerrados durante unos 15 minutos aproximadamente, transcurridos los cuales, se le realizará una primera revisión. Se le explicará el tratamiento a seguir y lo que puede o no puede hacer.
    </li></ul>
    Las primeras horas pueden ser incómodas, la visión todavía no es buena y puede aparecer sensación de arenilla, lagrimeo, fotofobia y, en algunos casos, dolor de leve a moderado. Por todo esto aconsejamos que durante las primeras horas postquirúrgicas se guarde reposo en una habitación con poca luz y si es posible se intente dormir.
    <br><br>
    Al día siguiente volveremos a realizar una revisión. En este momento la <b>agudeza visual ya suele ser muy buena y las molestias mínimas</b>. Esta rápida recuperación visual permite una reincorporación al trabajo muy precoz.
    <br><br>
    <p>Factores a tener en cuenta con esta técnica:</p>
    
    <ul><li>El láser Excimer actúa sobre la córnea y <b>NO tiene efectos sobre otras estructuras del ojo</b>, puesto que la luz que emite el láser solo actúa sobre la superficie que "toca", de forma que <b>NO puede inducir desprendimientos de retina</b>, cataratas, glaucoma, etc.
    </li><li><b><span>NO</span> "cura" la miopía</b>, la corrige, de forma que las lesiones retinianas propias de la miopía no se modificaran (ni mejoran ni empeoran).
    </li><li><b><span>SI</span> es posible reoperar un ojo</b> en el caso de que en el futuro reapareciera una miopía o la corrección no hubiese sido suficiente.
    </li><li><b><span>NO</span> impide</b> el poder realizar en el futuro <b>otro tipo de intervención</b>.</li></ul>'
    />
    <Accordion
        active={false}
        title="PREGUNTAS FRECUENTES"
        content='<h4>¿El LASIK es una intervención dolorosa?</h4>

    Durante la intervención se puede sentir sensación de tacto, de presión, pero no de dolor. En el postoperatorio inmediato, durante las primeras 5 ó 6 horas, puede aparecer sensación de arenilla, picazon, lagrimeo, fotofobia. Lo normal es que al día siguiente prácticamente no se tengan molestias.
    
    <h4>¿Podré ver mejor que con mis anteojos o lentes de contacto?</h4>
    
    El objetivo de la cirugía refractiva es quitar la dependencia de las lentes correctoras alcanzando la misma agudeza visual. <b>La máxima visión que un paciente tiene con sus anteojos o con sus lentes de contacto es la máxima visión que tendrá tras un procedimiento refractivo, por lo tanto, si su visión es menor de lo normal no aumentará tras la cirugía aunque el resultado del tratamiento sea perfecto</b>. Sin embargo, en caso de defectos refractivos altos que se corrigen con lentes intraoculares o en casos de perfiles de ablación especiales se pueden obtener agudezas visuales superiores a las que se obtenían con gafas.
    
    <h4>¿Si me operó de láser me podré operar en un futuro de cataratas?</h4>
    
    Sí. No hay ninguna contraindicación para ser intervenido de cataratas en un futuro. Tampoco existe ningún problema si hubiera que realizar una intervención de retina o de glaucoma. El láser mejorará su visión sin lentes pero no influye en la frecuencia de aparición de cualquier otra enfermedad de los ojos. El ojo miope tiene más frecuencia por sí solo de desprendimiento de retina, cataratas, etc., estas alteraciones podrán ser operadas aunque usted esté operado con láser Excimer.
    
    <h4>¿Si me quedo embarazada después de la operación crecerán las dioptrías?</h4>
    
    No. Durante el embarazo no hay motivos para que las dioptrías aumenten. En nuestra experiencia se han operado muchas pacientes que tras el embarazo no han aumentado sus dioptrías.
    
    <h4>¿Qué ocurre con la presbicia o vista cansada?<h4>
    
    Este es un proceso evolutivo normal en las personas mayores de 40 años que consiste en la pérdida de la capacidad para enfocar objetos próximos, leer, coser, ver el monitor del ordenador. <b>El láser corregirá su miopía, hipermetropía o astigmatismo, pero no su presbicia, es decir, mejorará su visión de lejos, pero sólo podrán ver de cerca sin gafas los pacientes en los que no haya aparecido la presbicia por su edad</b>.
    
    <h4>¿Quedaré en cero dioptrías tras la cirugía?</h4>
    
    El LASIK es un procedimiento de gran exactitud. Las posibilidades de que quede algún defecto residual se relaciona con la magnitud del defecto a corregir y con la cicatrización. Hay que tener en cuenta que si el defecto refractivo residual es muy pequeño la agudeza visual puede ser totalmente normal, no siendo necesario en estos casos una reintervención.
    
    <h4>¿Cuánto tardaré en ver bien?</h4>
    
    En defectos pequeños la agudeza visual es cercana al 100% al día siguiente. En defectos mayores puede estar en un 80-90% recuperando el resto en los días siguientes. Por tanto, a las 48-72 horas podrá incorporarse en la mayoría de los casos a su trabajo habitual.
    
    <h4>¿Cuándo podré hacer deporte?</h4>
    
    Los deportes de no contacto se podrán realizar en las 48 horas siguientes. Los de contacto en unos 10-15 días. La natación se podrá realizar a los 15 días aproximadamente.
    
    <h4>¿Cuándo podré maquillarme?</h4>
    
    Al día siguiente de la operación siempre que no aplique directamente el maquillaje en la zona periocular en 15 días. El mismo día de la cirugía no debe llevar perfumes ni maquillaje.
    
    <h4>¿Después de la cirugía láser podría llevar lentes de contacto?</h4>
    
    Sí. Se pueden adaptar lentes de contacto, aunque lógicamente es muy raro que un paciente operado necesite tener que llevar lentes de contacto, ya que la exactitud del procedimiento es muy elevada.
    
    <h4>¿Es un tratamiento para siempre?</h4>
    
    El efecto del láser es para siempre pero a algunos pacientes con el paso del tiempo les puede volver a subir la miopía porque el ojo se hace más largo. Esta situación no es predecible antes de operarse, lo que sí se sabe es que usar anteojos, lentes de contacto, no usar nada o estar operado con láser, no influye en que aumente o en que no aumente el número de dioptrías de miopía.
    
    <h4>Después de una operación con LASIK ¿Qué cuidados debo de tener?</h4>
    
    Es de vital importancia evitar el frotamiento de los ojos. Deberá utilizar los colirios en la forma pautada. Es conveniente usar un anteojo como medida de protección en aquellas situaciones con riesgo de traumatismo o inclusión de partículas. Se desaconseja el maquillaje durante las primeras semanas, el sauna y los deportes acuáticos.'
    /></div>

export { cirugiaAccordion };