import Accordion from '../../components/accordion/Accordion';
import React from 'react';

const cataratasAccordion = <div className="accordion_cont">
    <h1>Cataratas</h1>
    <Accordion
        active={true}
        title="¿QUE SON LAS CATARATAS?"
        content='La catarata <b>es la opacificación total o parcial del cristalino</b> (una de las dos lentes naturales que tenemos en el ojo), reduciendo progresivamente la visión. La catarata está <b>normalmente asociada a la edad</b>, aunque puede ser congénita, producirse por traumatismos o ver acelerada su formación en determinadas enfermedades, así como tras la ingesta de algunos medicamentos.
        <br><br>
        Habitualmente la pérdida de su transparencia <b>ocurre de manera progresiva y lenta</b>, por lo cual, puede no apreciarse durante bastante tiempo y uno se puede acostumbrar a una agudeza visual que en realidad no es suficientemente buena.'
    />
    <Accordion
        active={false}
        title="SINTOMAS"
        content='Los siguientes son algunos de los síntomas que se pueden notar al tener una catarata. Si apreciamos alguno de ellos debemos acudir a una revisión ocular:
        <ul>
        <li>Sensación de visión borrosa que aumenta con el tiempo y que va dificultando actividades cotidianas</li>
        
        <li>No distingo la cara de otra persona hasta que la tengo muy cerca.</li>
        
        <li>Me cuesta leer letras pequeñas.</li>
        
        <li>No veo con claridad los escalones.</li>
        
        <li>Problemas con la visión nocturna que nos dificulta la conducción.</li>
        
        <li>Cambios frecuentes en la graduación de los anteojos.</li>
        
        <li>Podemos leer sin lentes cuando antes nos eran imprescindibles.</li>
        
        <li>Los colores son menos vivos, más apagados.</li>
        
        <li>Deslumbramientos en ambientes con una iluminación intensa o con las luces de los coches que nos vienen de frente.</li>
        
        <li>Halos alrededor de las luces.</li>
        
        <li>Incremento de la sensibilidad a la luz del sol.</li>
        
        <li>No producen dolor, picor, enrojecimiento, visión de moscas volantes.</li>
        
        <li>Además, puede aparecer una miopía que nunca había existido, es decir, que se vean desenfocados los objetos lejanos.</li>
        </ul>
        <b>Inevitablemente, una catarata siempre avanza y no hay medicamentos, hábitos de vida o dieta que influya en su desaparición.</b>
        <br><br>
        
        Cuando se produce la catarata tenemos que cambiar esa lente natural por una artificial nueva. Por tanto <b>el tratamiento de una catarata es exclusivamente quirúrgico</b>. El diagnóstico se hará mediante un examen ocular. Por eso es importante que ante la aparición de alguno de los síntomas comentados acudamos a nuestro oftalmólogo.'
    />
    <Accordion
        active={false}
        title="TRATAMIENTO"
        content='Como ya hemos comentado el tratamiento es exclusivamente <b>quirúrgico</b>. Lo primero que habría que plantearse es:<br><br>

        <h4>¿Cuándo se debe realizar la operación de cataratas?</h4>
        
        En realidad <b>es un procedimiento que se puede llevar a cabo en cualquier momento de su evolución</b>, pero hay que tener en cuenta que:
        
        <ul><li>La catarata va evolucionando continuamente</li>
        
        <li>Al final la solución va a ser siempre la cirugía</li>
        
        <li>Cuanto más madura es la catarata más dificultad tiene la cirugía y mayor es el riesgo de complicaciones.</li>
        </ul>
        
        Por eso, de forma general, el momento más adecuado para la cirugía de cataratas sería cuando el paciente empieza a notar dificultades para su vida cotidiana.
        <br><br>

        <h4>¿Qué es la facoemulsificación?</h4>
        
        La facoemulsificación es una pequeña microincisión que introducimos (menor de 3 mm) mediante un terminal de ultrasonidos con el que vamos fragmentando el núcleo de la catarata, respetando su cápsula, y aspirando los fragmentos hemos producido.
        <br><br>
        Posteriormente introducimos una lente intraocular plegable, de un material sintético, dentro de ese saco o cápsula, con la que intentamos corregir defectos refractivos que hubieran previos a la cirugía (hipermetropía, miopía, astigmatismo, presbicia).
        <br><br>
        Como la incisión es valvulada, habitualmente no necesita puntos de sutura para cerrarla, se sella sola. La anestesia que utilizamos en la mayoría de los casos es la tópica, lo que quiere decir que sólo empleamos varias gotas de un colirio sin necesidad de pinchar ni inyectar anestésico alrededor del ojo.
        <br><br>
        <h4>¿Cuáles son las ventajas?</h4>
        <ul>
        <li>Muy poco tiempo en el quirófano. Sin dolor.</li>
        
        <li>No es necesario pinchar anestesia ni dar puntos de sutura.</li>
        
        <li>No necesitamos tapar el ojo al acabar la operación.</li>
        
        <li>No necesitará ingreso ni hospitalización y unos minutos tras la cirugía se podrá ir a casa.</li>
        
        <li>La recuperación visual es rápida y la reincorporación a su vida cotidiana será en días.</li>
        
        <li>Necesitará pocas visitas postoperatorias, generalmente tres.</li>
        
        <li>Podremos corregir el defecto refractivo previo que tuviera (miopía, hipermetropía, astigmatismo o presbicia), dependiendo de la lente intraocular que implantemos y reduciendo por tanto la necesidad de utilizar gafas posteriormente.</li>
        
        <li>No necesitamos suspender la medicación de ningún paciente, lo cual es especialmente importante en pacientes anticoagulados.</li>
        
        <li>No importa la edad o las enfermedades que tenga un paciente. Siempre podremos realizar una cirugía sin hospitalización.</li>
        </ul>
        <h4>Lentes Premium</h4>
        
        Durante mucho tiempo, la cirugía de catarata tradicional consistía en reemplazar el cristalino afectado sin tener en cuenta la refracción del paciente. Por ese motivo los pacientes debían utilizar siempre lentes después de la cirugía para lejos, cerca o ambos.
        <br><br>
        Actualmente y gracias a la introducción de las Lentes Premium, lentes intraoculares multifocales diseñadas para conseguir <b>eliminar la dependencia de gafas</b>, podemos conseguir una visión correcta a todas las distancias pemitiendo una buena visión de <b>lejos, a distancia intermedia y de cerca</b>, según las necesidades y exigencias visuales de cada paciente. La calidad de la visión tras una cirugía de catarata o presbicia con implante de LIO, viene determinada por las características de la lente seleccionada. Tipos:
        <ul>
        <li>Lentes multifocales bifocales (Distancias Lejos-Cerca)</li>
        
        <li>Lentes multifocales trifocales (Distancia lejos- intermedia- cerca)</li>
        
        <li>Lentes tóricas (corrigen el astigmatismo)</li>
        
        <li>Lentes intraoculares multifocales bifocales tóricas (con astigmatismo)</li>
        
        <li>Lentes intraoculares multifocales trifocales tóricas (con astigmatismo)</li></ul>'
    />
    <Accordion
        active={false}
        title="PREGUNTAS FRECUENTES"
        content='<h4>¿La catarata puede volver a salir tras la cirugía?</h4>

        No, aunque con el tiempo se puede opacificar el saco o cápsula que se deja en la cirugía para sostener la lente y la sensación del paciente es de una visión borrosa similar a la que tenía con la catarata, pero NO es otra catarata. La solución es muy sencilla, con láser, sin pasar por el quirófano y con una recuperación inmediata de la agudeza visual. Este problema no puede volver a ocurrir una segunda vez.
        <br>
        <h4>¿Cuándo puedo hacer vida normal después de una cirugía de cataratas? ¿Cuantos días de reposo absoluto se necesitan?</h4>
       
        La mayoría de las actividades cotidianas se pueden realizar enseguida, aunque no se deben levantar grandes pesos los primeros días.
        <br>
        <h4>¿Qué cuidados se deben tener tras una cirugía de cataratas?</h4>
       
        Fundamentalmente cumplir el tratamiento de gotas que nos prescriba nuestro oftalmólogo y acudir a la consulta si notamos dolor, perdida de visión brusca o cualquier otro síntoma que no notábamos antes de la última revisión.
        <br>
        <h4>¿Se puede viajar en avión después de una operación de cataratas?</h4>
        
        Tras comprobar en las primeras revisiones que el postoperatorio es normal, se puede viajar en cualquier medio de transporte.
        <br>
        <h4>¿Es una operación dolorosa?</h4>
       
        En el postoperatorio inmediato se puede tener alguna molestia leve que normalmente no precisa analgésicos.
        <br>
        <h4>¿Cuánta tiempo dura una lente implantada en el ojo tras una cirugía de cataratas?</h4>
       
        La lente intraocular está fabricada con un material biocompatible cuyo tiempo de degradación es muy superior al de cualquier tejido vivo, por lo que dura toda la vida.'
    />
    </div>

export { cataratasAccordion };