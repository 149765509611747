import Accordion from '../../components/accordion/Accordion';
import React from 'react';

const corneaAccordion = <div className="accordion_cont"><h1>Cornea y superficie ocular</h1>
    <Accordion
        active={true}
        title="¿QUE ES LA CORNEA Y LA SUPERFICIE OCULAR?"
        content='La <b>córnea</b> es la ventana del ojo. Transparente y curvilínea actúa como la primera lente que encuentra la luz cuando penetra en nuestro globo ocular. De su transparencia depende la capacidad visual de cada persona; pero, además, la córnea cumple funciones defensivas frente a traumatismos e infecciones. Muchas enfermedades adquiridas o congénitas le afectan. Es de las especialidades que más innovaciones técnicas de diagnóstico y cirugía ha recibido en los últimos años.
        <br><br>
        Por su parte, la <b>superficie ocular</b> está formada por unas estructuras que trabajan de forma conjunta para proteger el ojo y proporcionar una buena visión. Son las glándulas lagrimales y los párpados, encargados de la producción y distribución de la lágrima, y la conjuntiva y la córnea, muy sensibles a las agresiones externas.'
    />
    <Accordion
        active={false}
        title="PATOLOGIAS"
        content='Hay muchas patologías frecuentes que afectan a estas estructuras. Por su importancia podemos destacar:
        <ul>
        <li><b>Opacidad corneal:</b> pérdida en la transparencia de la córnea, que puede producirse por traumatismos, inflamación o por causas hereditarias. En estos casos, un transplante de córnea soluciona el problema.
        </li>
        <li><b>Infecciones:</b> los procesos infecciosos pueden producirse en la conjuntiva (conjuntivitis), en el borde del párpado (blefaritis), o en la córnea (úlceras corneales, queratitis).
        </li>
        <li><b>Síndrome del ojo seco:</b> es la incapacidad del sistema lagrimal para mantener lubricada y protegida la superficie ocular. Se manifiesta por sensación de cuerpo extraño, enrojecimiento ocular, lagrimeo y sequedad ocular. Es especialmente frecuente en mujeres en la 5ª o 6ª décadas de la vida. Existen muchos tipos de ojo seco, de ahí que de su correcta identificación dependa el éxito del tratamiento.
        </li>
        <li><b>Pterigión:</b> crecimiento anómalo de la conjuntiva que puede llegar a cubrir la córnea, dificultando la visión.
        </li>
        <li>Hay muchas enfermedades propias de la córnea, tanto hereditarias como adquiridas, y entre ellas el <b><span>queratocono<span></b> tiene una especial transcendencia.</li></ul>'
    />
    </div>

export { corneaAccordion };