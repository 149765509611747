import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import IndexPage from "./pages/indexPage/IndexPage";
import MedicsPage from "./pages/medicsPage/MedicsPage";
import SpecialityPage from "./pages/specialityPage/SpecialityPage";
import AppointmentTutorialPage from "./pages/appointmentTutorialPage/AppointmentTutorialPage";
import StudyPage from "./pages/studyPage/StudyPage";
import Header from "./components/header/Header";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Footer from "./components/footer/Footer";
import ContactoPage from './pages/contacPage/ContactPage';
import ScrollToTop from './components/scrollToTop/ScrollToTop';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <div className="app-container">
        <Header />
        <ScrollToTop />
        <Switch>
          <Route exact path="/" render={() => <IndexPage />} />
          <Route path="/medicos" render={() => <MedicsPage />} />
          <Route path="/cirugiaRefractiva" render={() => <SpecialityPage />} />
          <Route
            path="/degeneracionMaculura"
            render={() => <SpecialityPage />}
          />
          <Route path="/cornea" render={() => <SpecialityPage />} />
          <Route path="/presbicia" render={() => <SpecialityPage />} />
          <Route path="/cataratas" render={() => <SpecialityPage />} />
          <Route path="/glaucoma" render={() => <SpecialityPage />} />
          <Route path="/retina" render={() => <SpecialityPage />} />
          <Route path="/OCT" render={() => <StudyPage />} />
          <Route path="/HRT" render={() => <StudyPage />} />
          <Route path="/biometria" render={() => <StudyPage />} />
          <Route path="/ecografia" render={() => <StudyPage />} />
          <Route path="/endotelio" render={() => <StudyPage />} />
          <Route path="/CVC" render={() => <StudyPage />} />
          <Route
            path="/contacto"
            render={() => <ContactoPage />} />
            <Route path="/turnos" render={() => <AppointmentTutorialPage />} />
          <Route component={IndexPage} />          
        </Switch>
        <Footer />
      </div>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
