import React from "react";
import "./AppointmentTutorialPage.scss";
import AlertDialog from '../../components/alertModal/AlertModal';
import { Button } from '@material-ui/core';


function AppointmentTutorialPage() {

  const redirectToAppointmentPage = () =>{
    //window.location.href = "http://comorbelli.dyndns.org/treelan/turnos_online/logme.php";
    window.open('http://comorbelli.dyndns.org/treelan/turnos_online/logme.php', '_blank','noopener');
  }

  return (
    <div className="container">
      <div className="appointment-wrapper">
        <div className="shortcuts-cont">
          <h5>Accesos Rapidos</h5>
          <ul>
            <li><a href="http://comorbelli.dyndns.org/treelan/turnos_online/logme.php" target="_blank" rel="noopener noreferrer">Registrarme</a></li>
            <li><a href="#appointment-tuto-child"> ¿Como solicito turno para niños y estudios?</a></li>           
            <li><a href="#register">¿Como me registro en la pagina de turnos?</a></li>
            <li><a href="#appointment-tuto">¿Como puedo solicitar un turno?</a></li>
          </ul>
        </div>
        <div className="main-content">
          <div id="info-gral" className="info-gral">
            <h1>Turnos online</h1>
            <p id="appointment-tuto-child">Para sacar tu turno online hace click en el boton de abajo. Si todavía no sabes como registrarte o
            como sacar turnos en la pagina te recomiendo que sigas leyendo</p> 
            <Button variant="contained" onClick={redirectToAppointmentPage} color="primary">
              Sacar turno
            </Button>           
          </div>

          <div className="sep"></div>

          <div className="appointment-tuto-child">
            <h2>¿Como solicito turno para niños y estudios?</h2>
            <p>Los turnos para controles escolares, niños y estudios se otorgarán únicamente por vía telefónica o por WhatsApp</p>
            <div className="phone-number">
              <img src={require("../../assets/images/phone.svg")} alt="Teléfono"></img>
              <p>
                Atención al paciente: (54 11) 4292 - 2776 | 4245 - 1717 / 5433
              </p>
            </div>
            <div className="info-wpp">
              <a
                href="https://api.whatsapp.com/send?phone=+541153761195"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require("../../assets/images/whatsapp.svg")}
                  alt="Whatsapp"
                ></img>
                <p>Turnos por Whatsapp +541153761195</p>
              </a>
            </div>
          </div>

          <div className="sep"></div>
          <div className="register-tuto" id="register">
            <h2>¿Como me registro en la pagina de turnos?</h2>

            <div id="step1" className="steps">
              <div>
                <h3>Paso 1</h3>
                <p>Una vez que hayas ingresado en la pagina de turnos deberas hacer click <strong>"Generar tu contraseña"</strong> para dar de alta tu usuario</p>
              </div>
              <div>
                <img className="tutorial-image" src={require("../../assets/images/Turnos online/Registro/paso 1.png")}></img>
              </div>
            </div>

            <div id="step2" className="steps">
              <div>
                <h3>Paso 2</h3>
                <p>Luego de que carge el modal debera ingresar su numero de documento y luego hacer click <strong>"Genere tu contraseña"</strong></p>
              </div>
              <div>
                <img className="tutorial-image step2" src={require("../../assets/images/Turnos online/Registro/paso 2.png")}></img>
              </div>
            </div>

            <div id="step3" className="steps">
              <div>
                <h3>Paso 3</h3>
                <p>Una vez generada la contraseña debera llenar el formulario con los datos solicitados y luego hacer click en <strong>"Registrarse"</strong></p>
              </div>
              <div>
                <img className="tutorial-image step2" src={require("../../assets/images/Turnos online/Registro/paso 3.png")}></img>
              </div>
            </div>

            <div id="step4" className="steps">
              <div>
                <h3>Paso 4</h3>
                <p>Por ultimo si todo salio bien deberia aparecerle este mensaje en pantalla.</p>
              </div>
              <div>
                <img className="tutorial-image step2" src={require("../../assets/images/Turnos online/Registro/paso 3 bis.png")}></img>
              </div>
            </div>
          </div>

          <div className="sep"></div>

          <div className="appointment-tuto" id="appointment-tuto">
            <h2>¿Como puedo solicitar un turno?</h2>

            <div id="step1" className="steps">
              <div>
                <h3>Paso 1</h3>
                <p>Si es la primera vez que ingresa al sistema debera completar el formulario con los siguientes datos y luego
                  hacer click en el boton <strong>"Cargar Datos"</strong> para dar de alta tu usuario</p>
              </div>
              <div>
                <img className="tutorial-image" src={require("../../assets/images/Turnos online/Sacar turno/paso 4.png")}></img>
              </div>
            </div>

            <div id="step2" className="steps">
              <div>
                <h3>Paso 2</h3>
                <p>En esta sección debera elegir la sede, la especialidad y el medico con el cual desea sacar el turno</p>
              </div>
              <div>
                <img className="tutorial-image" src={require("../../assets/images/Turnos online/Sacar turno/paso 5.png")}></img>
              </div>
            </div>

            <div id="step3" className="steps">
              <div>
                <h3>Paso 3</h3>
                <p>Por ultimo deberas seleccionar el día y el horario en el cual deseas atenderte y luego hacer click en el boton <strong>"Reservar"</strong></p>
              </div>
              <div>
                <img className="tutorial-image" src={require("../../assets/images/Turnos online/Sacar turno/paso 6.png")}></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlertDialog/>
    </div>
  );
}

export default AppointmentTutorialPage;
