import Accordion from '../../components/accordion/Accordion';
import React from 'react';

const glaucomaAccordion = <div className="accordion_cont"><h1>Glaucoma</h1>
    <Accordion
        active={true}
        title="¿QUE ES EL GLAUCOMA?"
        content='Es la lesión de las fibras del nervio óptico producido por un aumento de la presión intraocular.
        <br><br>
        El nervio óptico transmite las imágenes del ojo al cerebro y el aumento de la presión intraocular va afectando de manera lenta y progresiva a sus fibras, produciendo una lesión que es irreversible. Dependiendo del grado de destrucción del nervio óptico la pérdida de visión es mayor o menor.'
    />
    <Accordion
        active={false}
        title="UNA ENFERMEDAD FRECUENTE A PARTIR DE LOS 40 AÑOS"
        content='El glaucoma es una enfermedad frecuente que afecta a un 2% de la población mayor de 40 años y a un 14 % de los mayores de 60 años. El problema es que se calcula que sólo la mitad de los pacientes con glaucoma saben que padecen esta enfermedad y esto se debe a que, en un principio, el glaucoma no produce síntomas, no produce dolor ni ojo rojo. Sólo produce una pérdida de visión periférica que en estadios precoces le es muy difícil de detectar al paciente.'
    />
    <Accordion
        active={false}
        title="SINTOMAS Y DIAGNOSTICO"
        content='Existen varios tipos de glaucoma ocular pero el más frecuente es el glaucoma crónico de ángulo abierto, y éste no produce síntomas al principio. No hay dolor ni enrojecimiento y la visión se pierde de manera lenta y progresiva, primero en la zona periférica para acabar afectando a la zona central en estadios muy avanzados.
        <br><br>
        Esto hace que el paciente, con frecuencia, no sepa que tiene esta enfermedad hasta que ha avanzado mucho y hay que recordar que las lesiones provocadas por el glaucoma son <b>irreversibles</b>.
        <br><br>
        Por eso es muy importante la detección precoz acudiendo a una revisión oftalmológica con frecuencia, sobre todo en los casos de pacientes con antecedentes familiares de glaucoma.
        <br><br>
        El oftalmólogo puede controlar la tensión ocular y comprobar si hay lesión del nervio óptico, ayudado por diversas pruebas complementarias, como el estudio del campo visual que si está alterado traduce una lesión del nervio óptico.
        <br><br>
        El tratamiento precoz del glaucoma posibilita que se evite una alteración visual importante. Las personas que padecen glaucoma necesitan revisarse periódicamente a lo largo de su vida ya que el glaucoma puede empeorar sin que el paciente lo note.'
    />
    <Accordion
        active={false}
        title="TRATAMIENTO"
        content='Lo más importante en el tratamiento es la detección precoz de la enfermedad. Cuando un paciente tiene la tensión alta lo primero que se debe decidir es si necesita tratamiento o no. Igual que hay gente que tiene glaucoma y no lo sabe, hay gente que se está tratando y no lo necesita.
        <br><br>
        El objetivo del tratamiento es frenar la progresión de la enfermedad disminuyendo el valor de la presión intraocular. Normalmente aplicando gotas una o dos veces al día podemos controlar los niveles de tensión ocular y cuando esto no es suficiente utilizamos tratamiento con láser o con cirugía.
        <br><br>
        Al no tener síntomas hay ocasiones que el paciente no es consciente de su enfermedad y no es constante con el tratamiento, lo cual es fundamental para que el glaucoma no progrese.'
    />
    <Accordion
        active={false}
        title="PREGUNTAS FRECUENTES"
        content='<h4>¿Qué síntomas da el glaucoma?</h4>

        Por desgracia, e la mayoría de los tipos de presentación del glaucoma no suele dar ningún síntoma (salvo en el caso del glaucoma de cierre angular, cuyo dolor es comparado con "un dolor de clavo") hasta estadios muy avanzados donde la pérdida de campo de visión ya es notable
        
        <h4>¿Es verdad que no se puede curar y tan sólo ser controlado?</h4>
        
        Efectivamente, no podemos hablar de erradicar el cuadro sino de controlar y enlentencer su evolución
        
        <h4>¿Sólo existe tratamientos con gotas?</h4>
        
        Hay tres pilares terapéuticos fundamentales: el tratamiento tópico con hipotensiones, laserterapia y la cirugía
        
        <h4>¿En qué difiere la pérdida de visión por glaucoma a la que se padece, por ejemplo, en cataratas?</h4>
        
        La fundamental diferencia es la falta de reversibilidad, lo que perdamos, a diferencia de la catarata, no podrá volverse a recuperar. Por otra parte, al inicio lo que se pierde es campo de visión para finalmente perder la visión frontal'
    />
    </div>

export { glaucomaAccordion };