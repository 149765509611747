import React, { useState } from "react";
import "./ContactPage.scss";
import emailjs, { init } from 'emailjs-com';
import SnackBar from '../../components/snackbar/snackbar';
import SpinnerButton from '../../components/spinnerButton/SpinnerButton';


function ContactPage() {

  const [form, setForm] = useState({ name: "", email: "", subject: "", message: "" });
  const [error, setError] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [disabled, setDisabled] = useState(false);

  const [spinner, setSpinner] = useState({ loading: false, success: false });

  const [snack, setSnack] = useState({
    message: "", hideTimer: 0, status: false, severity: "success"
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm({
      ...form,
      [name]: value
    });
  }

  const handleSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnack({
      ...snack,
      status: false
    });
  };

  const checkValidation = () => {
    let errors = error;
    const { name, email, subject, message } = form;

    if (name.trim() !== "") {
      name.length < 5 ? errors.name = "El nombre debe tener minimo 5 caracteres" : errors.name = "";
    }
    else {
      errors.name = "El nombre es obligatorio";
    }

    if (email.trim() !== "") {
      validEmailRegex.test(email) ? errors.email = "" : errors.email = "El formato de email no es correcto";
    }
    else {
      errors.email = "El email es obligatorio";
    }

    if (subject.trim() !== "") {
      subject.length < 5 ? errors.subject = "El asunto debe tener minimo 5 caracteres" : errors.subject = "";
    }
    else {
      errors.subject = "El asunto es obligatorio";
    }

    if (message.trim() !== "") {
      message.length < 5 ? errors.message = "El mensaje debe tener minimo 5 caracteres" : errors.message = "";
    }
    else {
      errors.message = "El mensaje es obligatorio";
    }

    setError({ ...error });
  }

  function sendEmail(e) {
    e.preventDefault();

    if (!spinner.loading) {
      setSpinner({ loading: true, success: false });
    }
    setDisabled(true);

    checkValidation();

    if (validateForm(error)) {
      const { name, email, subject, message } = form;

      console.log("vamos para adelante");
      var templateParams = {
        name: name,
        email: email,
        subject: subject,
        message: message
      };
      //Ver porque no toma el userId y el service del .env
      let service = "service_d7pslgr";
      let templateId = "template_fk4ogsj";
      let userId = "user_vq1JHLGEIUdsafiSybYQP";

      emailjs.send(service, templateId, templateParams, userId)
        .then((result) => {
          setDisabled(false);
          setSpinner({ loading: false, success: true });
          setForm({
            name: '',
            email: '',
            subject: '',
            message: ''
          })
          setSnack({
            message: "El email se envio correctamente", hideTimer: 3000, status: true, severity: "success"
          });

        }, (error) => {
          setSpinner({ loading: false, success: true });
          setDisabled(false);
          setSnack({
            message: "Ocurrio un error, vuelva a intentar mas tarde", hideTimer: 3000, status: true, severity: "error"
          });
        });
    }
    else {
      setDisabled(false);
      setSpinner({ loading: false, success: true });
      console.log("mostramos errores");
    }





  }

  const validEmailRegex = RegExp(
    /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
  );
  const validateForm = errors => {
    let valid = true;
    Object.values(errors).forEach(val => val.length > 0 && (valid = false));
    return valid;
  };

  return (
    <div className="container">
      <div className="wrapper">
        <div className="inner">
          <form onSubmit={sendEmail} className="form-container">
            <div>
              <h3>Comunicate con nosotros</h3>
              <div className="sep"></div>
            </div>
            <div className="form-group">
              <label>
                Nombre *
          <input className={"form-control " + (error.name ? 'input-error' : '')} value={form.name}
                  type="text" name="name" onChange={handleChange} disabled={disabled} />
              </label>
              {error.name.length > 0 &&
                <span className='error'>{error.name}</span>}
            </div>
            <div className="form-group">
              <label>
                Email *
          <input className={"form-control " + (error.email ? 'input-error' : '')} value={form.email}
                  type="text" name="email" onChange={handleChange} disabled={disabled} />
              </label>
              {error.email.length > 0 &&
                <span className='error'>{error.email}</span>}
            </div>
            <div className="form-group">
              <label>
                Asunto *
          <input className={"form-control " + (error.subject ? 'input-error' : '')} value={form.subject}
                  type="text" name="subject" onChange={handleChange} disabled={disabled} />
              </label>
              {error.subject.length > 0 &&
                <span className='error'>{error.subject}</span>}
            </div>
            <div className="form-group">
              <label>
                Mensaje *
          <textarea className={"form-control " + (error.message ? 'input-error' : '')} value={form.message}
                  name="message" onChange={handleChange} rows="4" cols="50" maxLength="200" disabled={disabled} />
              </label>
              {error.message.length > 0 &&
                <span className='error'>{error.message}</span>}
            </div>
            <SpinnerButton label="Enviar" loading={spinner.loading}
              success={spinner.success} onClick={sendEmail}></SpinnerButton>
          </form>
        </div>
      </div>
      <SnackBar message={snack.message} status={snack.status} hideTimer={snack.hideTimer}
        severity={snack.severity} handleClose={handleSnackBar}></SnackBar>
    </div>
  );
}

export default ContactPage;
