import React from 'react';

const OCTContent = <div className="study-content">
<h1>OCT (tomografia de coherencia Optica)</h1>
<div className="sep"></div>

La Tomografía de coherencia óptica (OCT) es una técnica de diagnóstico no invasiva que trasmite una imagen en sección transversal en vivo de la retina. Debido a la transparencia del ojo (es decir, la retina se puede ver a través de la pupila), la OCT ha ganado gran popularidad como una herramienta de diagnóstico oftálmologico
<h4>Para que se utiliza?</h4>

OCT es útil en el diagnóstico de muchas enfermedades de la retina. En general, las lesiones en la mácula (central) son más fáciles de evaluar que las lesiones perifericas. OCT es particularmente útil en el diagnóstico:
<ul>
<li>Agujero Macular</li>
<li>“Pucker” (pliegue) macular</li>
<li>Tracción vítreo-macular</li>
<li>Edema Macular</li>
<li>Desprendimientos de la retina neurosensorial y el epitelio pigmentario de la retina</li> 
<li>Ateraciones del nervio óptico</li>
<li>Cuantificacion de daño por presión intraocular</li>
<li>Evaluacion de cornea y estructuras del polo anterior del ojo</li>
<li>Seguimiento de patologías exudativas de retina y de enfermedades progresivas como el glaucoma.</li>
</ul>

<div className="study-img-cont">
<img src={require("../../assets/images/OCT.jpg")}></img>
<img src={require("../../assets/images/Informe OCT.jpg")}></img>
</div>

 
 </div>   

export { OCTContent };